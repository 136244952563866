import Axios from "../axios-interceptor";
import { dfoFormApiConstants, fileApiConstants, userApiConstants } from "../constants/AdminApiConstants";

export async function uploadFile(params) {
  return Axios.post(fileApiConstants.UPLOAD_FILE, params)
}

export async function fetchLoggedInUserInfo(params) {
  return Axios.put(userApiConstants.USER_INFO,params)
}

export async function generateForestCertificate(params) {
  return Axios.put(dfoFormApiConstants.PRINT_DFO_FORM,params)
}
