// @mui material components
import Grid from "@mui/material/Grid";

import * as React from "react";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import YardIcon from '@mui/icons-material/Yard';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import getNepaliNumber from "get-nepali-number";

// Data
import { fetchBarGraphData, fetchDashboardTilesData, fetchForestStatisticsData, fetchPieChartData } from "./Services";
import PieChart from "../../components/PieChart/PieChart";
import Card from "@mui/material/Card";
import ForestStatistics from "../../components/ForestStatistics/ForestStatistics";
import BarGraph from "../../components/BarGraph/BarGraph";


// Dashboard components
function Dashboard() {

  const [tiles, setTiles] = useState({});
  const [forestTypeList, setForestTypeList] = useState([]);
  const [channelTypeList, setChannelTypeList] = useState([]);
  const [forestStatisticsList, setForestStatisticsList] = useState([]);
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isPieLoading, setPieLoading] = useState(true);

  useEffect(async () => {
    console.log("tetewtewtewt");

    await fetchDashboardTiles();
    await fetchPieChart();
    await fetchBarGraph();
    await fetchForestStatistics();

  }, []);


  const fetchDashboardTiles = async () => {

    try {
      let requestDTO = {
        fiscalYear: "",
        fromDate: "",
        toDate: "",
      };
      const response = await fetchDashboardTilesData(requestDTO);
      const tiles = response.data;
      setTiles({
        totalSubmission: getNepaliNumber(tiles.totalSubmission),
        totalApproved: getNepaliNumber(tiles.totalApproved),
        totalRejected: getNepaliNumber(tiles.totalRejected),
        totalPrinted: getNepaliNumber(tiles.totalPrinted),
      });
      setIsLoading(false);
    } catch (e) {
      // alert(e.errorMessage);
    }

  };

  const fetchPieChart = async () => {

    try {

      const response = await fetchPieChartData();
      const { dataList } = response.data;
      setForestTypeList(dataList);
      setPieLoading(false);
    } catch (e) {
      // alert(e.errorMessage);
    }

  };
  const fetchBarGraph = async () => {

    try {
      const response = await fetchBarGraphData();
      const { dataList } = response.data;
      setChannelTypeList(dataList);
      setPieLoading(false);
    } catch (e) {
      // alert(e.errorMessage);
    }

  };
  const fetchForestStatistics = async () => {

    try {

      const response = await fetchForestStatisticsData();
      const list = response.data;
      setForestStatisticsList(list);
      setIsStatisticsLoading(false);
    } catch (e) {
      // alert(e.errorMessage);
    }

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="forest"
                title="जम्मा पेश गरिएको फारम"
                count={tiles.totalSubmission}
                // <>
                //   {/*{isLoading === true ? <Spinner /> : tiles?.daily}*/}
                // </>}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="description"
                title="स्वीकृत फारम सबमिशन"
                count={tiles.totalApproved}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="description"
                title="अस्वीकृत फारम सबमिशन"
                count={tiles.totalRejected}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="print"
                title="छापिएको फारम"
                count={tiles?.totalPrinted}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox py={5}>
        <Grid container spacing={1}>
          <Grid item xs={3} md={3} lg={3}>
            <Card>
              <PieChart
                isPieLoading={isPieLoading}
                forestTypeList={forestTypeList} />
            </Card>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Card>
              <BarGraph
              channelTypeList={channelTypeList}/>
            </Card>
          </Grid>

          <Grid item xs={5} md={5} lg={5}>
              <ForestStatistics
                isStatisticsLoading={isStatisticsLoading}
                forestStatisticsList={forestStatisticsList} />
          </Grid>
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          {/*<img src={nepal} width={500} height={500} />*/}
        </Grid>
      </MDBox>
      <MDBox py={5}>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
    ;
};

export default Dashboard;
