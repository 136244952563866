import * as React from "react";
import {useEffect} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function FormSubmissionApprovalModal(props) {

    const {
        showApproval,
        showApprovalModal,
        formSubmission,
        formSubmissionId,
        handleApproveFormSubmission
    } = props;

    console.log(showApproval);

    useEffect(async () => {

        // if (showDetail) {
        //     // await fetchActiveCategoryForDropdown();
        // }

    }, [showApproval]);


    const {
        name,
        citizenship,
        contact,
        varietyName,
        varietyNumber,
        isApproved,
        description,
        submissionDate,
        status
    } = formSubmission;

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={showApproval}
                onClose={() => showApprovalModal(false)}>
                <DialogTitle>फारम सबमिशन विवरण</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    {/*  Create new Item*/}
                    {/*</DialogContentText>*/}

                    <Box
                        component="form"
                        // sx={{
                        //     "& > :not(style)": {m: 1},
                        // }}
                        noValidate
                        autoComplete="off"
                    >
                        तपाई यो फारम सबमिशन स्वीकृत गर्न चाहनुहुन्छ?
                        <br></br>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" color={"success"}
                                    onClick={() => handleApproveFormSubmission(formSubmissionId, "Y")}> हो < /Button>
                            <Button variant="contained" color={"warning"}
                                    onClick={() => handleApproveFormSubmission(formSubmissionId, "N")}> होइन < /Button>
                        </Stack>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => showApprovalModal(false)}>रद्द
                        गर्नुहोस्</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FormSubmissionApprovalModal;
