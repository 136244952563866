// export const BASE = "http://localhost:8082/dfo/api/v1";
export const BASE = "https://api.technovateconsult.com/palika-forest/api/v1";

const DASHBOARD_BASE = "/dashboard";
const DFO_FORM_BASE = "/dfo-form";
const ADMIN_BASE = "/admin";

const DETAIL = "/detail";
export const FILE_BASE = "/file";
export const DOWNLOAD = "/download";

const ALL = "/all";
const FILE = "/file";
const SEARCH = "/search";
const USER_INFO = "/info";
// const DETAIL = '/detail'
// const TRANSFER = '/transfer'
// const INFO = '/info'
const LOGIN = "/login";
const TILES = "/tiles";
// const PIE_CHART = '/pie-chart'

const UPLOAD = "/upload";
// const DOWNLOAD = '/download'

const ACTIVE = "/active";
const MIN = "/min";

// ------------ADMIN-----------------------------

export const dashboardApiConstants = {
  FETCH_DASHBOARD_TILES: BASE.concat(DASHBOARD_BASE.concat("/tiles")),
  FETCH_PIE_CHART: BASE.concat(DASHBOARD_BASE.concat("/pie-chart")),
  FOREST_STATISTICS: BASE.concat(DASHBOARD_BASE.concat("/statistics")),
  BAR_GRAPH: BASE.concat(DASHBOARD_BASE.concat("/bar-graph")),
};


export const fileApiConstants = {
  UPLOAD_FILE: BASE.concat(FILE_BASE.concat(UPLOAD)),
  DOWNLOAD_FILE: BASE.concat(FILE_BASE),
};

export const loginApiConstants = {
  LOGIN_USER: BASE.concat(LOGIN),
}

export const userApiConstants = {
  SAVE_USER: BASE.concat(ADMIN_BASE),
  SEARCH_USER: BASE.concat(ADMIN_BASE.concat(SEARCH)),
  USER_INFO: BASE.concat(ADMIN_BASE.concat(USER_INFO)),
};

export const dfoFormApiConstants = {
  SAVE_DFO_FORM: BASE.concat(DFO_FORM_BASE).concat("/webapp"),
  SEARCH_DFO_FORM: BASE.concat(DFO_FORM_BASE),
  FETCH_DFO_FORM: BASE.concat(DFO_FORM_BASE.concat(DETAIL)),
  APPROVE_DFO_FORM: BASE.concat(DFO_FORM_BASE.concat("/approve")),
  PRINT_DFO_FORM: BASE.concat(DFO_FORM_BASE.concat("/print")),
};

// ------------CLIENT-----------------------------
