import React, { useEffect, useState } from "react";
import "./new.css";
import { useParams } from "react-router-dom";
import { fetchDfoFormSubmissionDetail } from "../../layouts/formSubmission/Services";
import { getTokenFromLocalStorage } from "../../utils/LocalStorageUtils";
import getNepaliNumber from "get-nepali-number";
import Button from "@mui/material/Button";
import { generateForestCertificate } from "../../layouts/CommonServices";

const NewCertificate = (props) => {
  const routeParams = useParams();
  const { id } = routeParams;

  const [formSubmission, setFormSubmission] = useState([]);
  const [company, setCompany] = useState("");
  const [divName, setDivName] = useState("frontPrint");

  useEffect(async () => {
    const fetchData = async () => {
      const response = await fetchDfoFormSubmissionDetail(id);
      const formSubmission = response.data;
      setFormSubmission(formSubmission);

      const company = await getTokenFromLocalStorage("userInfo");
      setCompany(company);
    };
    await fetchData();
  }, [id]);

  console.log("company:::", company)
  const print = async (divName) => {
    let requestDTO = {
      id: id,
    };
    await generateForestCertificate(requestDTO);
    if (divName === 'bothPrintableArea') {
      await setDivName(divName)
    }
    if (divName === 'frontPrint') {
      await setDivName(divName)
    }
    if (divName === 'backPrint') {
      await setDivName(divName)
    }
    setTimeout(() => {
      window.print(divName)
    }, 2000);
  }

  return (
    <>
      <div className="notPrintableArea">
        <Button className="font-weight-bold" md="12" style={{ marginTop: "10px" }}
                onClick={() => print("frontPrint")}><Button />&nbsp;प्रिन्ट गर्ने
        </Button>&nbsp;
      </div>
      <div id="p1"
           className="cardHeight"
           style={{
             overflow: "inherit",
             position: "relative",
             backgroundColor: "white",
             width: "1210px",
             height: "935px",
           }}
        // style={{
        //   overflow: "hidden",
        //   position: "relative",
        //   backgroundColor: "white",
        //   width: "1210px",
        //   height: "935px",
        // }}
      >

        {/*<div id="pg1Overlay"*/}
        {/*     style={{*/}
        {/*       width: "100%",*/}
        {/*       height: "100%",*/}
        {/*       position: "absolute",*/}
        {/*       zIndex: 1,*/}
        {/*       backgroundColor: "rgba(0,0,0,0)",*/}
        {/*       WebkitUserSelect: "none"*/}
        {/*     }}></div>*/}
        {/*<div id="pg1" style={{ }}>*/}
        {/*  <object width="1210" height="935" data="1/1.svg" type="image/svg+xml" id="pdf1"*/}
        {/*          style={{*/}
        {/*            width: "1210px",*/}
        {/*            height: "935px",*/}
        {/*            MozTransform: "scale(1)",*/}
        {/*            zIndex: 0,*/}
        {/*          }}></object>*/}
        {/*</div>*/}

        <div className="text-container">
          <span id="t1_1" className="t m0_1 s1">अनुूसूची</span>
          <span id="t2_1" className="t s2">–४७</span>
          <span id="t3_1" className="t s2"></span>
          <span id="t4_1" className="t s2">(नियम ८१ को उपनियम (३) सँग सम्बन्धित)</span>
          <span id="t5_1" className="t s3">निजी वन दर्ता प्रमाणपत्र{" "} </span>
          <span id="t6_1" className="t s4">{company.companyNameNp} </span>
          <span id="t7_1" className="t s4">{company.companyTypeCode === "MUN" ? "नगरपालिकाको कार्यालय" :
            (company.companyTypeCode === "RURAL-MUN" ?
              "गाउँ कार्यपालिकाको कार्यालय" : "")}</span>
          <span id="t8_1" className="t s4">{company.districtName}</span>

          <span id="t9_1"
                className="t s5"> प्रमाणपत्र नं: <b>{formSubmission.registrationNumber ? getNepaliNumber(formSubmission.registrationNumber) : "N/A"}</b> </span>

          <span id="ta_1" className="t s6">श्री {formSubmission.name} </span><span id="tb_1" className="t s6">मिति– २०८...÷...÷..... </span>

          <span id="tc_1" className="t s6">{company.localLevelNameNp + "-"} {company.districtName}। </span><span
          id="td_1"
          className="t s6"> </span>

          <span id="te_1" className="t s6"> तपाईले {company.districtName} डिभिजन वन कार्यालयको सिफारिस सहित यस हरिवन नगरपालिकामा निजी वन दर्ता गराई पाउँ</span>
          <span id="tf_1" className="t s6">भनी पेस गरेको कागजात अध्ययन हुदाँ वन ऐन, २०७६ र वन नियमावली, २०७९ को अधिनमा
                                        रही देहायको निजी वन तथा</span>
          <span id="tg_1" className="t s6">निजी आवादी जमिनमा लगाएको वन पैदावारलाई निजी वनको रुपमा दर्ता गरी यो प्रमाणपत्र दिइएको छ । </span>
          <span id="tg_1" className="t s6"></span>
          <span id="tg_1" className="t s6"></span>
          <span id="tg_1" className="t s6"></span>

          <span id="th_1" className="t s7"><b><ul>निजी वनको विवरण :-</ul></b> </span>
          <span id="ti_1" className="t s7"><b>चार किल्ला :</b> </span>
          <span id="tj_1" className="t s2">पूर्व :-  </span><span id="tk_1" className="t s2">{formSubmission?.east ? getNepaliNumber(formSubmission.east) : ""} </span>
          <span id="tl_1" className="t s2">पश्चिम :- </span><span id="tm_1" className="t s2">{formSubmission?.west ? getNepaliNumber(formSubmission.west) : ""}</span>
          <span id="tn_1" className="t s2">उत्तर :- {formSubmission?.north ? getNepaliNumber(formSubmission.north) : ""}</span>
          <span id="to_1" className="t s2">दक्षिण :- {formSubmission?.south ? getNepaliNumber(formSubmission.south) : ""}</span>
          <span id="tp_1" className="t s2">कित्ता नं. र क्षेत्रफल :- {formSubmission?.area ? getNepaliNumber(formSubmission.area) : ""}</span>
          <span id="tq_1" className="t s2">वन पैदावारको प्रजाती र संख्या :- {formSubmission?.varietyNumber ? getNepaliNumber(formSubmission.varietyNumber) : ""}</span>

          <span id="tr_1" className="t m0_1 s8">प्रमाणपत्र दिने अधिकृतको :- </span>
          <span id="ts_1" className="t v0_1 s2">नाम,थर :- {company?.fullName}</span>
          <span id="tt_1" className="t v0_1 s2">दर्जा :- {company.designationEng} </span>
          <span id="tu_1" className="t v1_1 s7">दस्तखत :- </span>
          {/*<span id="tv_1" className="t s7">b|i6Jo M– </span>*/}

          <span id="tw_1" className="t m0_1 s9"><b>द्रष्टव्य :–</b> यो प्रमाणपत्र हराएमा, च्यातिएमा, वा नासिएमा अर्को प्रमाणपत्र लिन पच्चीस रुपैयाँ दस्तुर लाग्नेछ ।</span>

          {/*<span id="tx_1" className="t m0_1 s9">द्रष्टव्य :– यो प्रमाणपत्र हराएमा, च्यातिएमा, वा नासिएमा अर्को प्रमाणपत्र लिन पच्चीस रुपैयाँ दस्तुर लाग्नेछ ।</span>*/}
        </div>
      </div>
    </>

  );
};

export default NewCertificate;
