import Axios from "../../axios-interceptor";
import {dfoFormApiConstants} from "../../constants/AdminApiConstants";

export async function saveDfoForm(params) {
    return Axios.post(dfoFormApiConstants.SAVE_DFO_FORM, params);
}

export async function searchDfoFormSubmission(data) {
    return Axios.put(dfoFormApiConstants.SEARCH_DFO_FORM, data, {params: {page: 1, size: 10}});
}

export async function fetchDfoFormSubmissionDetail(id) {
    return Axios.get(dfoFormApiConstants.FETCH_DFO_FORM + `/${id}`);
}

export async function approveFormSubmission(data) {
    return Axios.put(dfoFormApiConstants.APPROVE_DFO_FORM, data);
}
