import * as React from "react";
import { BarChart } from "@mui/x-charts";

export default function BarGraph(props) {
  const{channelTypeList}=props
  return (
    <>
      <h3>च्यानलको विवरण</h3>
      {channelTypeList.length>0?
    <BarChart
      xAxis={[
        {
          id: "barCategories",
          data: ["वेबसाइट", "प्रणाली"],
          scaleType: "band",
        },
      ]}
      series={[
        {
          data: channelTypeList,
          color:"#014D33"
        },
      ]}
      width={500}
      height={300}
    />:""}
    </>
  );
}