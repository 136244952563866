// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import * as React from "react";
import { useEffect, useState } from "react";
import { searchDfoReport } from "./Services";
import Card from "@mui/material/Card";
import DenseTable from "./Test";
import { appendSNToDataList } from "../../utils/TableUtils";
import Button from "@mui/material/Button";
import exportToCSV from "./ExportExcel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReportBody from "./ReportBody";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import { getTokenFromLocalStorage } from "../../utils/LocalStorageUtils";

function Report() {

  const [itemList, setItemList] = useState([]);
  const [isLoading,setIsLoading]=useState(true)
  const [company, setCompany] = useState("");
  const [searchFilter, setSearchFilter] = useState({
    name: "",
    status: "",
  });

  const [searchParams,setSearchParams]=useState({name:""})

  useEffect(async () => {
    setCompany(getTokenFromLocalStorage("userInfo"));
    await fetchItemSearch();
    setIsLoading(false)
  }, []);

  const handleSearch = async (event) => {

    // const {name, value} = event.target;
    // setSearchFilter({
    //       name: value,
    //   });
    await fetchItemSearch();
  };

  const fetchItemSearch = async () => {
    try {
      const response = await searchDfoReport(searchFilter);
      const itemList = response.data;
      setItemList(itemList);
    } catch (e) {
      // alert(e.errorMessage);
      setItemList([]);
    }
  };

  const SearchBar = (props) => {
    const {handleSearch, searchParams, searchSeniorPerson} = props;

    const {idType, name, citizenshipNumber, idNo, ward} = searchParams;

    const _exportPdf = () => {
      const input = document.getElementById("detail_report_body");
      console.log(input);
      html2canvas(input).then((canvas) => {
        // console.log(canvas.toDataURL("/image/png"));
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "a2");

        // new code
        // pdf.addFileToVFS('Ananda Akchyar.ttf', imgData)
        // pdf.addFont('Ananda Akchyar.ttf', 'custom', 'normal');
        // new code

        pdf.addImage(imgData, "JPEG", 1, 2);

        console.log(pdf.addImage(imgData, "JPEG", 1, 2));
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      });
    };
    return (
      <div>
        <div
          className="d-flex justify-content-end"
          style={{marginTop: "10px", marginBottom: "10px", marginRight: "0px"}}
        >
          <Button
            color={"success"}
            style={{marginRight: "10px"}}
            onClick={_exportPdf}
          >
            <PictureAsPdfIcon
              color={"success"}
              fontSize={"large"}
            />
            &nbsp;PDF
          </Button>

          <Button
            color={"success"}
            onClick={() => exportToCSV(itemList, "report")}
          >
          <ArticleIcon
            color={"success"}
            fontSize={"large"}
          />
            &nbsp;
            Excel
          </Button>
        </div>
      {/*  <Row style={{marginTop: "10px", marginBottom: "10px"}}>*/}
      {/*    <CCol xs="12" md="2" style={{paddingRight: "0px"}}>*/}
      {/*      <CInput*/}
      {/*        id="idNo"*/}
      {/*        name="idNo"*/}
      {/*        value={idNo}*/}
      {/*        onChange={handleSearch}*/}
      {/*        placeholder={LanguageConstants.IdNo}*/}
      {/*      />*/}
      {/*    </CCol>*/}
      {/*    <CCol xs="12" md="2" style={{paddingRight: "0px"}}>*/}
      {/*      <CSelect*/}
      {/*        custom*/}
      {/*        name="idType"*/}
      {/*        id="select"*/}
      {/*        value={idType}*/}
      {/*        onChange={handleSearch}*/}
      {/*      >*/}
      {/*        <option value="0">किसिम</option>*/}
      {/*        <option value="HELPLESS">असहाय</option>*/}
      {/*        <option value="SINGLE">एकल</option>*/}
      {/*        <option value="DISABLED"> अशक्त</option>*/}
      {/*      </CSelect>*/}
      {/*    </CCol>*/}
      {/*    <CCol xs="12" md="2">*/}
      {/*      <CInput*/}
      {/*        id="name"*/}
      {/*        name="name"*/}
      {/*        value={name}*/}
      {/*        onChange={handleSearch}*/}
      {/*        placeholder={LanguageConstants.FullNameNp}*/}
      {/*      />*/}
      {/*    </CCol>*/}

      {/*    <CCol xs="12" md="2">*/}
      {/*      <CInput*/}
      {/*        id="citizenshipNumber"*/}
      {/*        name="citizenshipNumber"*/}
      {/*        value={citizenshipNumber}*/}
      {/*        onChange={handleSearch}*/}
      {/*        placeholder="नागरिकता नं."*/}
      {/*      />*/}
      {/*    </CCol>*/}

      {/*    <CCol xs="12" md="2" style={{paddingRight: "0px"}}>*/}
      {/*      <CSelect*/}
      {/*        custom*/}
      {/*        name="ward"*/}
      {/*        id="ward"*/}
      {/*        value={ward}*/}
      {/*        onChange={handleSearch}*/}
      {/*      >*/}
      {/*        {WardList &&*/}
      {/*          WardList.map((item) => (*/}
      {/*            <option key={item.id} value={item.nameEng}>*/}
      {/*              {item.nameNp} ({item.nameEng})*/}
      {/*            </option>*/}
      {/*          ))}*/}
      {/*      </CSelect>*/}
      {/*    </CCol>*/}

      {/*    /!* <CCol xs="12" md="2" style={{ paddingRight: '0px' }}>*/}
      {/*  <CSelect*/}
      {/*    custom*/}
      {/*    name="status"*/}
      {/*    id="select"*/}
      {/*    value={status}*/}
      {/*    onChange={handleSearch}*/}
      {/*  >*/}
      {/*    <option value="0">स्ट्याटस्</option>*/}
      {/*    <option value="P"> प्रकृयामा</option>*/}
      {/*    <option value="A"> स्वीकृत</option>*/}
      {/*    <option value="R"> अस्वीकृत</option>*/}
      {/*    <option value="T"> खिचिएको</option>*/}
      {/*  </CSelect>*/}
      {/*</CCol> *!/*/}

      {/*    <CCol xs="12" md="2">*/}
      {/*      <Button color={"success"} onClick={searchSeniorPerson}>*/}
      {/*        <FaSearch/>*/}
      {/*        {LanguageConstants.Search}*/}
      {/*      </Button>*/}
      {/*    </CCol>*/}
      {/*  </Row>*/}
      </div>
    );
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SearchBar
        handleSearch={handleSearch}
        searchParams={searchParams}
        fetchItemSearch={fetchItemSearch}
        itemList={appendSNToDataList(itemList)}
      />

      <Card>
        <MDBox>
        <ReportBody
          company={company}
          id="detail_report_body"
          isLoading={isLoading}
          itemList={itemList}
          />
        </MDBox>
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default Report;
