import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
import FormSubmission from "./layouts/formSubmission/FormSubmission";
import Logout from "./layouts/Logout/Logout";
import CertificateLayout from "./layouts/authentication/components/CertificateLayout";
import Report from "./layouts/report/Report";
import NewCertificate from "./components/Certificate/NewCertificate";

const routes = [
  {
    type: "collapse",
    name: "ड्यासबोर्ड",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "फारम सबमिशन",
    key: "tables",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/tables",
    component: <FormSubmission />,
  },
  {
    type: "collapse",
    name: "रिपोर्टिङ",
    key: "reports",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/reports",
    component: <Report />,
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "साइन आउट गर्नुहोस्",
    key: "sign-out",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/logout",
    component: <Logout />,
  },

  {
    type: "hidden",
    name: "",
    key: "certificate",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/certificate/:id",
    component:
    <>
      <CertificateLayout>
        <NewCertificate/>
      </CertificateLayout>
    </>
  },
];

export default routes;
