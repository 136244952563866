import { useEffect, useState } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow } from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { setTransparentNavbar, useMaterialUIController } from "context";
import { getTokenFromLocalStorage } from "../../../utils/LocalStorageUtils";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    const userInfo = getTokenFromLocalStorage("userInfo") || "";
    setUser(userInfo);

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);


  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />

        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/*<MDBox pr={1}>*/}
            {/*  <MDInput label="Search here" />*/}
            {/*</MDBox>*/}
            <MDBox color={light ? "white" : "inherit"}>
              <Card sx={{ minWidth: 100, minHeight: 5, fontSize: 10 }}>

                <CardContent>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography sx={{ fontSize: 14 }} component="div">
                        {/*<Link to="/authentication/sign-in/basic">*/}
                        {/*  <IconButton sx={navbarIconButton} size="small" disableRipple>*/}
                        {/*    <Icon sx={iconsStyle}>account_circle</Icon>{user?.fullName}*/}
                        {/*  </IconButton>*/}
                        {/*</Link>*/}
                        <b>{user ? user.email : ""}</b><br></br>
                        {user ? user.nameEng : ""}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }} component="div">
                        {user ? user.designationEng : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Avatar size={"10"} alt={user ? user.nameEng : "A"} src="/static/images/avatar/1.jpg" />
                    </Grid>
                  </Grid>

                </CardContent>

              </Card>
            </MDBox>

          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
