import { Pie } from "react-chartjs-2";
import React from "react";
import Spinner from "../Loader/Spinner";

interface DatasetType {
  data: number[];
  backgroundColor: string[];
}

function PieChart(props) {

  const { isPieLoading,forestTypeList } = props;

  console.log("data::",forestTypeList)

  return (
    (isPieLoading?
      <div><Spinner/></div>:
    <Pie
      aria-setsize={5}
      options={{
        width: "30",
        height: "30",
      }}
      data={{
        labels: ["निजि वन दर्ता", "वन उद्यम दर्ता"],
        datasets: [
          {
            data: forestTypeList,
            backgroundColor: ["#003f5c", "#58508d"],
          },
        ],
      }}
    />)
  );
}

export default PieChart;
