import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import { appendSNToDataList } from "../../utils/TableUtils";
import { ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import getNepaliNumber from "get-nepali-number";

export default function FormSubmissionTable(props) {

  const { itemList, setShowDetail, showApprovalModal } = props;

  const anchorRef = React.useRef(null);

  const showDetailModal = (id) => {
    setShowDetail(id, true);
  };

  const handleApprove = (id) => {
    showApprovalModal(id, true);
  };

  const handleReject = (id) => {
    showApprovalModal(id, true);
  };

  const columns = [
    { field: "sn", headerName: "क्रम संख्या", width: 100 },
    { field: "registrationNumber", headerName: "दर्ता नम्बर", width: 100,
      valueGetter: (params) =>
        `${params.row.registrationNumber?getNepaliNumber(params.row.registrationNumber):"N/A" || ""}`,},
    { field: "name", headerName: "नाम", width: 200 },
    // {
    //   field: "varietyName", headerName: "प्रजातिको नाम", width: 150,
    //   valueGetter: (params) =>
    //     `${params.row.varietyName || ""}`,
    // },
    // {
    //   field: "varietyNumber", headerName: "प्रजातिको सन्ख्या", width: 150,
    //   valueGetter: (params) =>
    //     `${getNepaliNumber(params.row.varietyNumber) || ""}`,
    // },
    { field: "contact", headerName: "सम्पर्क", width: 120 },
    { field: "submissionDate", headerName: "पेश गरिएको मिति", width: 150 },
    { field: "channel", headerName: "च्यानल", width: 100,
      renderCell: (params) => (
        <MDBox ml={-1}>
          <MDBadge badgeContent={params.row.channel} color="info" variant="gradient" size="sm" />
        </MDBox>
      )},
    {
      field: "status", headerName: "स्थिति", width: 100,
      renderCell: (params) => (
        <>
          {params.row.isApproved === "Y" ?
            <MDBox ml={-1}>
              <MDBadge badgeContent="स्वीकृत भएको" color="success" variant="gradient" size="sm" />
            </MDBox> :
            (params.row.isApproved === "N" ?
              <MDBox ml={-1}>
                <MDBadge badgeContent="अस्वीकार गरिएको" color="error" variant="gradient" size="sm" />
              </MDBox> :
              <MDBox ml={-1}>
                <MDBadge badgeContent="स्वीकृत नभएको" color="warning" variant="gradient" size="sm" />
              </MDBox>)
          }
        </>
      ),
    },
    {
      field: "कार्यहरू", headerName: "कार्यहरू", width: 600, height: 800,
      renderCell: (params) => (
        <React.Fragment>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              key="three"
              sx={{ backgroundColor: "blue" }}
              onClick={(event) => showDetailModal(params.row.id)}>विवरण हेर्नुहोस्</Button>
            {params.row.isApproved === null ?
              <>
              <Button
                key="one"
                color={"error"}
                sx={{ backgroundColor: "red" }}
                onClick={(event) => handleApprove(params.row.id)}>अस्वीकार गर्नुहोस्</Button>
              <Button
                key="two"
                color={"success"}
                sx={{ backgroundColor: "green" }}
                onClick={(event) => handleReject(params.row.id)}>स्वीकार गर्नुहोस्</Button>
              </>
              :""}
            {params.row.isApproved === 'Y'?
              <Button
                key="two"
                color={"success"}
                sx={{ backgroundColor: "green" }}
                target="_blank"
                href={"/certificate/" + params.row.id}>प्रमाणपत्र उत्पन्न गर्नुहोस्</Button>
              :""}
              </ButtonGroup>
        </React.Fragment>
      ),
    },
  ];

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <DataGrid
        rows={appendSNToDataList(itemList)}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        test={"test"}
        loading={itemList.length === 0}
        ref={anchorRef.current}
        experimentalFeatures={{ newEditingApi: true }}
        // checkboxSelection
      />
    </div>
  );
}
