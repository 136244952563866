// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import FormSubmissionTable from "./FormSubmissionTable";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  approveFormSubmission,
  fetchDfoFormSubmissionDetail,
  searchDfoFormSubmission,
} from "./Services";
import MDTypography from "../../components/MDTypography";
import IconButton from "@mui/material/IconButton";
import { AddBox, SearchRounded } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormControl, Select, Stack } from "@mui/material";
import FormSubmissionAdd from "./FormSubmissionAdd";
import FormSubmissionDetail from "./FormSubmissionDetail";
import FormSubmissionApprovalModal from "./FormSubmissionApprovalModal";
import MenuItem from "@mui/material/MenuItem";

function FormSubmission() {

  const [itemList, setItemList] = useState([]);
  const [formSubmission, setFormSubmission] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [formSubmissionId, setFormSubmissionId] = useState(null);
  const [showApproval, setShowApproval] = useState(false);
  const [searchFilter, setSearchFilter] = useState({
    name: "",
    varietyName: "",
    status: "",
  });

  useEffect(async () => {
    await fetchItemSearch();
  }, []);

  const handleSearch = async (event) => {

    event.preventDefault();
    const { name, value } = event.target;
    setSearchFilter({
      ...searchFilter,
      [name]: value,
    });
    await fetchItemSearch();
  };

  const fetchItemSearch = async () => {
    try {
      const response = await searchDfoFormSubmission(searchFilter);
      const itemList = response.data;
      setItemList(itemList);
    } catch (e) {
      // alert(e.errorMessage);
      setItemList([]);
    }
  };

  const showAddModal = async (flag) => {
    setShowAdd(flag);
  };

  const showApprovalModal = async (id, flag) => {
    setFormSubmissionId(id);
    setShowApproval(flag);
  };

  const handleApproveFormSubmission = async (id, status) => {
    let requestDTO = {
      id: id,
      remarks: "Test",
      status: status,
    };
    const response = await approveFormSubmission(requestDTO);
    if (response)
      setShowApproval(false);
    await handleSearch();
  };

  const showDetailModal = async (id, flag) => {
    setShowDetail(flag);
    if (flag) {

      const response = await fetchDfoFormSubmissionDetail(id);
      const formSubmission = response.data;
      setFormSubmission(formSubmission);
    }

  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {showAdd ?
        <FormSubmissionAdd
          showAddModal={showAddModal}
          showAdd={showAdd}
          fetchItemSearch={fetchItemSearch}
        /> : ""}

      {showDetail ?
        <FormSubmissionDetail
          setShowDetail={showDetailModal}
          showDetail={showDetail}
          formSubmission={formSubmission}
        /> : ""}

      {showApproval ?
        <FormSubmissionApprovalModal
          showApprovalModal={showApprovalModal}
          showApproval={showApproval}
          formSubmission={formSubmission}
          formSubmissionId={formSubmissionId}
          handleApproveFormSubmission={handleApproveFormSubmission}
        /> : ""}

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

              <MDBox
                mx={2}
                mt={-4}
                py={1}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  नयाँ दर्ता
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => showAddModal(true)}
                    color="inherit"
                  >
                    <AddBox />
                  </IconButton>
                </MDTypography>

              </MDBox>

              <MDBox px={1} py={1}>
                <Stack direction="row" spacing={2}>
                  {/*<TextField id="outlined-basic" label="प्रजातिको नाम" name={""}*/}
                  {/*           onChange={handleSearch}*/}
                  {/*           variant="outlined"/>*/}

                  <FormControl
                    sx={{ width: 200, minHeight: 50, height: 50 }}
                    variant="outlined">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-autowidth-label"
                      name={"varietyName"}
                      value={searchFilter.varietyName}
                      label={"प्रजातिको नाम"}
                      placeholder={"प्रजातिको नाम"}
                      sx={{ width: 200, minHeight: 40, height: 40 }}
                      onChange={(e) => handleSearch(e)}
                    >
                      <MenuItem value={"फिरफिरे"}>फिरफिरे</MenuItem>
                      <MenuItem value={"जामुन"}>जामुन</MenuItem>
                      <MenuItem value={"गुलाब जामुन"}>गुलाब जामुन</MenuItem>
                      <MenuItem value={"खयर"}>खयर</MenuItem>
                      <MenuItem value={"दबदबे"}>दबदबे</MenuItem>
                      <MenuItem value={"टाँकी"}>टाँकी</MenuItem>
                      <MenuItem value={"साल"}>साल</MenuItem>
                      <MenuItem value={"बिजयसाल"}>बिजयसाल</MenuItem>
                      <MenuItem value={"गमारी"}>गमारी</MenuItem>
                      <MenuItem value={"अमला"}>अमला</MenuItem>
                      <MenuItem value={"कटहर"}>कटहर</MenuItem>
                      <MenuItem value={"बडहर"}>बडहर</MenuItem>
                      <MenuItem value={"आँप"}>आँप</MenuItem>
                      <MenuItem value={"चाँप"}>चाँप</MenuItem>
                      <MenuItem value={"अगर-वृक्ष"}>अगर-वृक्ष</MenuItem>
                      <MenuItem value={"कागति"}>कागति</MenuItem>
                      <MenuItem value={"इमली, तिसी"}>इमली, तिसी</MenuItem>
                      <MenuItem value={"वर"}>वर</MenuItem>
                      <MenuItem value={"पिपल"}>पिपल</MenuItem>
                      <MenuItem value={"पिपल"}>पिपल</MenuItem>
                      <MenuItem value={"समी"}>समी</MenuItem>
                      <MenuItem value={"लाँकुरी"}>लाँकुरी</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="नाम"
                    name={"name"}
                    onChange={handleSearch}
                    value={searchFilter.name}
                    variant="outlined" />
                  <TextField id="outlined-basic" label="स्थिति" variant="outlined" />
                  <Button
                    variant="contained"
                    startIcon={<SearchRounded />}
                    onClick={() => fetchItemSearch()}
                  >
                    खोजि
                  </Button>
                </Stack>

              </MDBox>

              <MDBox py={3}>
                <FormSubmissionTable
                  setShowDetail={showDetailModal}
                  showApprovalModal={showApprovalModal}
                  itemList={itemList} />
              </MDBox>

            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default FormSubmission;
