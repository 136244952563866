import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import Grid from "@mui/material/Grid";
import { BASE, DOWNLOAD, FILE_BASE } from "../../constants/AdminApiConstants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import getNepaliNumber from "get-nepali-number";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function FormSubmissionDetail(props) {

  const {
    showDetail,
    setShowDetail,
    formSubmission,
  } = props;

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    id,
    name,
    citizenship,
    contact,
    isApproved,
    description,
    submissionDate,
    lalpurja,
    east,
    west,
    north,
    south,
    registrationNumber
  } = formSubmission;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={showDetail}
        onClose={() => setShowDetail(false)}>
        <DialogTitle>फारम सबमिशन विवरण</DialogTitle>
        <DialogContent>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab key={"detail"} label="विवरण" {...a11yProps(0)} />
              <Tab key={"document"} label="कागजातहरू" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="h6" fontWeight="medium">
                    नाम: {name}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="h6" fontWeight="medium">
                    सम्पर्क: {contact}
                  </MDTypography>
                </MDBox>

                <Card variant="outlined">
                    <CardContent>
                      <Typography variant={"h5"} gutterBottom>
                        प्रजातिहरुको विवरण:
                      </Typography>

                      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                        {formSubmission.dfoVarietyInfoResponse && formSubmission.dfoVarietyInfoResponse.map((dfoVarietyInfo,index) => (
                          <ListItem
                            key={index}
                            disableGutters
                            secondaryAction={
                              <IconButton aria-label="comment">
                                <>
                                  {dfoVarietyInfo.varietyNumber?getNepaliNumber(dfoVarietyInfo.varietyNumber):"N/A"}
                                </>
                              </IconButton>
                            }
                          >
                            <ListItemText primary={`${dfoVarietyInfo.varietyName}`} />
                          </ListItem>
                        ))}
                      </List>

                    </CardContent>
                </Card>
                <br></br>
                <Card variant="outlined">
                    <CardContent>
                      <Typography variant={"h5"} gutterBottom>
                        चार किल्ला:
                      </Typography>
                      <Typography variant="h6" component="div">
                        पूर्व: {east ? getNepaliNumber(east) : "N/A"}
                      </Typography>
                      <Typography variant="h6" component="div">
                        पश्चिम: {west ? getNepaliNumber(west) : "N/A"}
                      </Typography>
                      <Typography variant="h6" component="div">
                        उत्तर: {north ? getNepaliNumber(north) : "N/A"}
                      </Typography>
                      <Typography variant="h6" component="div">
                        दक्षिण:{south ? getNepaliNumber(south) : "N/A"}
                      </Typography>
                    </CardContent>
                </Card>
                <br></br>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography>बिरुवा लगाउने स्थानको विवरण :&nbsp;&nbsp;&nbsp;
                    <TextField size={"100"} fullWidth value={description}>
                    </TextField>
                  </MDTypography>
                </MDBox>

                <br></br><br></br>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography>
                    दर्ता नम्बर: {registrationNumber ? getNepaliNumber(registrationNumber) : "N/A"}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography>
                    पेश गरिएको मिति: {submissionDate}
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h6" fontWeight="medium">
                  स्थिति :
                  {isApproved === "Y" ?
                    <MDBox ml={-1}>
                      <MDBadge badgeContent="स्वीकृत भएको" color="success"
                               variant="gradient"
                               size="sm" />
                    </MDBox> :
                    (isApproved === "N" ?
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="अस्वीकार गरिएको" color="error"
                                 variant="gradient" size="sm" />
                      </MDBox> :
                      <MDBox ml={-1}>
                        <MDBadge badgeContent="स्वीकृत नभएको" color="warning"
                                 variant="gradient"
                                 size="sm" />
                      </MDBox>)
                  }
                </MDTypography>
              </Grid>
              <Grid item xs={4}>
                {isApproved === "Y" ?
                  <Button
                    variant="contained"
                    color={"success"}
                    target="_blank"
                    href={"/certificate/" + id}>प्रमाणपत्र उत्पन्न गर्नुहोस्</Button> : ""}
              </Grid>
            </Grid>

          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <MDTypography variant="caption">
                  <h2>नगरिकता: </h2>&nbsp;&nbsp;&nbsp;
                  <img
                    src={`${BASE + FILE_BASE + "/" + citizenship}?w=100&fit=crop&auto=format`}
                    srcSet={`${BASE + FILE_BASE + "/" + citizenship}?w=100&fit=crop&auto=format&dpr=2 2x`}
                    width={250}
                    heigth={250}
                    alt={"citizenship"}
                    loading="lazy"
                  />
                </MDTypography>
              </Grid>
              <Grid item xs={1}>
                <Button variant="text" onClick={() => setShowDetail(false)}>हेर्नुहोस्</Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="text" href={BASE + FILE_BASE + DOWNLOAD + "/" + citizenship}>डाउनलोड गर्नुहोस्</Button>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption">
                    <h2>लालपुरजा: </h2>&nbsp;&nbsp;&nbsp;
                    <img
                      src={`${BASE + FILE_BASE + "/" + lalpurja}?w=100&fit=crop&auto=format`}
                      srcSet={`${BASE + FILE_BASE + "/" + lalpurja}?w=100&fit=crop&auto=format&dpr=2 2x`}
                      width={250}
                      heigth={250}
                      alt={"lalpurja"}
                      loading="lazy"
                    />
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={1}>
                <Button variant="text" onClick={BASE + FILE_BASE + "/" + lalpurja}>हेर्नुहोस्</Button>
              </Grid>
              <Grid item xs={1}>
                <Button variant="text" href={BASE + FILE_BASE + DOWNLOAD + "/" + lalpurja}>डाउनलोड गर्नुहोस्</Button>
              </Grid>
            </Grid>
          </CustomTabPanel>

        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setShowDetail(false)}>रद्द गर्नुहोस्</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormSubmissionDetail;