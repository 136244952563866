import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import {
  createSvgIcon,
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { uploadFile } from "../CommonServices";
import Grid from "@mui/material/Grid";
import { saveDfoForm } from "./Services";
import { PhotoCamera } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { BASE, FILE_BASE } from "../../constants/AdminApiConstants";

const PlusIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  "Plus",
);

function FormSubmissionAdd(props) {

  const {
    showAdd,
    showAddModal,
    fetchItemSearch,
  } = props;

  const [formSubmission, setFormSubmission] = useState({
    name: "",
    citizenship: "",
    contact: "",
    description: "",
    lalpurja: "",
    varietyInfoList: [{
      varietyName: "",
      varietyNumber: null,
    }],
  });

  useEffect(async () => {
    if (showAdd) {
    }
  }, [showAdd]);

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name + ":::" + value);

    setFormSubmission({
      ...formSubmission,
      [name]: value,
    });

  };

  const handleVarietyOnChange = async (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log(name + ":::" + value + ":::" + index);

    const { varietyInfoList } = formSubmission;
    varietyInfoList[index][name] = value;
    console.log("ddddd==>", varietyInfoList);
    // await setFormSubmission({
    //   varietyList: [...varietyList],
    // });

  };

  const handleAddVariety = async (e) => {
    const { varietyInfoList } = formSubmission;

    varietyInfoList.push({
        varietyName: "",
        varietyNumber: null});
    console.log("handleRemoveVariety o/p==>", varietyInfoList.length);

    await setFormSubmission({
      ...formSubmission,
      varietyInfoList:[...varietyInfoList],
    });
  };

  const handleRemoveVariety = async (e, index) => {
    e.preventDefault();
    console.log("handleRemoveVariety==>", index);
    const { varietyInfoList } = formSubmission;

    varietyInfoList.splice(index, 1);
    await setFormSubmission({
      ...formSubmission,
      varietyInfoList,
    });


  };


  const submitFormSubmission = async () => {
    console.log("formSubmission==>>",formSubmission)
    try {
      await saveDfoForm(formSubmission);
      showAddModal(false);
      fetchItemSearch();
    } catch (e) {
      alert(e.errorMessage);
    }
  };

  const uploadImage = async (event) => {
    event.preventDefault();
    const { name, files } = event.target;
    console.log("name:::" + event.target);
    console.log("name:::" + name);
    console.log("files:::" + files);
    if (name === "citizenship" && files !== null) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("subDirectory", "citizenship");

      uploadFile(formData)
        .then(async (res) => {

          await setFormSubmission({
            ...formSubmission,
            "citizenship": res.data,
          });

        })
        .catch((e) => {
          alert("Error uploading file");
        });
    }

    if (name === "lalpurja" && files !== null) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("subDirectory", "lalpurja");

      uploadFile(formData)
        .then(async (res) => {

          await setFormSubmission({
            ...formSubmission,
            "lalpurja": res.data,
          });

        })
        .catch((e) => {
          alert("Error uploading file");
        });
    }
  };

  const {
    name,
    citizenship,
    contact,
    description,
    lalpurja,
    east,
    west,
    north,
    south,
    varietyInfoList,
  } = formSubmission;

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={showAdd}
        onClose={() => showAddModal(false)}>
        <DialogTitle>नयाँ दर्ता</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <div>
                <TextField
                  id="name"
                  sx={{ m: 1, width: "25ch" }}
                  name={"name"}
                  value={name}
                  label={"नाम"}
                  placeholder={"नाम"}
                  onChange={handleOnChange}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                <TextField
                  id="contact"
                  sx={{ m: 1, width: "25ch" }}
                  name={"contact"}
                  value={contact}
                  label={"सम्पर्क"}
                  placeholder={"सम्पर्क"}
                  onChange={handleOnChange}
                  type={"number"}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </div>

            </Grid>

            <div>
              <Typography variant={"h4"} color="text.secondary" gutterBottom>
                प्रजातिको विवरण:
              </Typography>
              <Card variant="outlined">
                {console.log("data...++>>>", varietyInfoList.length)}
                {varietyInfoList && varietyInfoList.map((variety, index) => (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <FormControl sx={{ width: 200, minHeight: 100, height: 100 }} variant="outlined">
                          <InputLabel htmlFor="component-simple">प्रजातिको नाम</InputLabel>

                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-autowidth-label"
                            name={"varietyName"}
                            value={variety.varietyName}
                            label={"प्रजातिको नाम"}
                            placeholder={"प्रजातिको नाम"}
                            fullWidth={true}
                            onChange={(e) => handleVarietyOnChange(e, index)}
                            sx={{ width: 200, minHeight: 50, height: 50 }}
                          >
                            <MenuItem value={"फिरफिरे"} selected={true}>फिरफिरे</MenuItem>
                            <MenuItem value={"जामुन"}>जामुन</MenuItem>
                            <MenuItem value={"गुलाब जामुन"}>गुलाब जामुन</MenuItem>
                            <MenuItem value={"खयर"}>खयर</MenuItem>
                            <MenuItem value={"दबदबे"}>दबदबे</MenuItem>
                            <MenuItem value={"टाँकी"}>टाँकी</MenuItem>
                            <MenuItem value={"साल"}>साल</MenuItem>
                            <MenuItem value={"बिजयसाल"}>बिजयसाल</MenuItem>
                            <MenuItem value={"गमारी"}>गमारी</MenuItem>
                            <MenuItem value={"अमला"}>अमला</MenuItem>
                            <MenuItem value={"कटहर"}>कटहर</MenuItem>
                            <MenuItem value={"बडहर"}>बडहर</MenuItem>
                            <MenuItem value={"आँप"}>आँप</MenuItem>
                            <MenuItem value={"चाँप"}>चाँप</MenuItem>
                            <MenuItem value={"अगर-वृक्ष"}>अगर-वृक्ष</MenuItem>
                            <MenuItem value={"कागति"}>कागति</MenuItem>
                            <MenuItem value={"इमली, तिसी"}>इमली, तिसी</MenuItem>
                            <MenuItem value={"वर"}>वर</MenuItem>
                            <MenuItem value={"पिपल"}>पिपल</MenuItem>
                            <MenuItem value={"पिपल"}>पिपल</MenuItem>
                            <MenuItem value={"समी"}>समी</MenuItem>
                            <MenuItem value={"लाँकुरी"}>लाँकुरी</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="contact"
                          sx={{ m: 1, width: "25ch" }}
                          name={"varietyNumber"}
                          value={variety.varietyNumber}
                          type={"number"}
                          min={0}
                          label={"प्रजातिको सन्ख्या"}
                          onChange={(e) => handleVarietyOnChange(e, index)}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="start"></InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          color="success"
                          onClick={(e) => handleAddVariety(e)}
                        >
                          <Add />
                        </IconButton>
                      </Grid>

                      {varietyInfoList.length > 1 ?
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={(e) => handleRemoveVariety(e, index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid> : ""}

                    </Grid>
                  </>
                ))}
              </Card>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Card style={{width:500}} variant="outlined">
                    <CardContent>
                      <Typography variant={"h3"} color="text.secondary" gutterBottom>
                        चार किल्ला:
                      </Typography>
                      <Typography variant="h5" component="div">
                        <FormControl variant="standard">
                          <InputLabel htmlFor="component-simple">पूर्व</InputLabel>
                          <Input
                            id="component-simple"
                            type={"number"}
                            name={"east"}
                            value={east}
                            onChange={handleOnChange} />
                        </FormControl>
                      </Typography>
                      <Typography variant="h5" component="div">
                        <FormControl variant="standard">
                          <InputLabel htmlFor="component-simple">पश्चिम:</InputLabel>
                          <Input
                            id="component-simple"
                            type={"number"}
                            name={"west"}
                            value={west}
                            onChange={handleOnChange} />
                        </FormControl>
                      </Typography>
                      <Typography variant="h5" component="div">
                        <FormControl variant="standard">
                          <InputLabel htmlFor="component-simple">उत्तर:</InputLabel>
                          <Input
                            id="component-simple"
                            type={"number"}
                            name={"north"}
                            value={north}
                            onChange={handleOnChange} />
                        </FormControl>
                      </Typography>
                      <Typography variant="h5" component="div">
                        <FormControl variant="standard">
                          <InputLabel htmlFor="component-simple">दक्षिण:</InputLabel>
                          <Input
                            id="component-simple"
                            type={"number"}
                            name={"south"}
                            value={south}
                            onChange={handleOnChange} />
                        </FormControl>
                      </Typography>
                    </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} md={6}>
                <Grid container spacing={4}>
                <Grid item xs={6} md={6}>
                  <FormControl variant="standard">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label">
                      नगरिकता:
                      <input
                        hidden accept="image/*"
                        type="file"
                        name={"citizenship"}
                        onChange={uploadImage}
                      />
                      <PhotoCamera />
                    </IconButton>
                  </FormControl>
                  {citizenship? <img
                    src={`${BASE + FILE_BASE + "/" + citizenship}?w=50&fit=crop&auto=format`}
                    srcSet={`${BASE + FILE_BASE + "/" + citizenship}?w=50&fit=crop&auto=format&dpr=2 2x`}
                    width={200}
                    height={100}
                    alt={citizenship}
                    loading="lazy"
                  />:""}
                </Grid>
                <Grid item xs={6} md={6}>
                  <FormControl variant="standard">
                    <IconButton
                      color="primary"
                      aria-label="upload lalpurja"
                      component="label">
                      लालपुरजा:
                      <input
                        hidden accept="image/*"
                        type="file"
                        name={"lalpurja"}
                        onChange={uploadImage}
                      />
                      <PhotoCamera />
                    </IconButton>
                  </FormControl>
                  {lalpurja?<img
                    src={`${BASE + FILE_BASE + "/" + lalpurja}?w=50&fit=crop&auto=format`}
                    srcSet={`${BASE + FILE_BASE + "/" + lalpurja}?w=50&fit=crop&auto=format&dpr=2 2x`}
                    alt={lalpurja}
                    loading="lazy"
                    width={200}
                    height={100}
                  />:""}
                </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid>
                <FormControl variant="standard">
                  <InputLabel htmlFor="component-simple">बिरुवा लगाउने स्थानको विवरण</InputLabel>
                  <TextareaAutosize
                    style={{width:1100}}
                    size={"large"}
                    minRows={5}
                    minLength={10}
                    id="component-simple"
                    name={"description"}
                    value={description}
                    onChange={handleOnChange} />
                </FormControl>
              </Grid>
            </Grid>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => showAddModal(false)}>रद्द गर्नुहोस्</Button>
          <Button variant="text" onClick={() => submitFormSubmission()}>पेश गर्नुहोस्</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormSubmissionAdd;
