import Axios from "../../axios-interceptor";
import { dashboardApiConstants } from "../../constants/AdminApiConstants";

export async function fetchDashboardTilesData(data) {
  return Axios.put(dashboardApiConstants.FETCH_DASHBOARD_TILES,data);
}

export async function fetchPieChartData() {
  return Axios.get(dashboardApiConstants.FETCH_PIE_CHART);
}
export async function fetchBarGraphData() {
  return Axios.get(dashboardApiConstants.BAR_GRAPH);
}
export async function fetchForestStatisticsData() {
  return Axios.get(dashboardApiConstants.FOREST_STATISTICS);
}
