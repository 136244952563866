import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TableCell, TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Spinner from "../Loader/Spinner";
import getNepaliNumber from 'get-nepali-number';

export default function ForestStatistics(props) {
  const { isStatisticsLoading, forestStatisticsList } = props;
  console.log("forestStatisticsList", forestStatisticsList);
  return (
    <Card sx={{ maxWidth: 400 }}>
      {/*<CardMedia*/}
      {/*  component="img"*/}
      {/*  height="100"*/}
      {/*  width="400"*/}
      {/*  image={img}*/}
      {/*  alt="green iguana"*/}
      {/*/>*/}
      <CardContent>
        <h6>अधिकांश प्रजातिहरू</h6>
        <TableContainer component={Paper}>
          {isStatisticsLoading ?
            <Spinner />
            : (forestStatisticsList && forestStatisticsList.length > 0 ?
              <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>प्रजातिहरू</TableCell>
                    <TableCell>गणना</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {forestStatisticsList && forestStatisticsList.map((forestStatistics) => (
                    <TableRow
                      key={forestStatistics.varietyName}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{forestStatistics.varietyName}</TableCell>
                      <TableCell>{getNepaliNumber(forestStatistics.count)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              : <>
                <br></br>
                डाटा उपलब्ध छैन
              </>)}
        </TableContainer>
      </CardContent>
    </Card>
  );
}