import React from "react";
import getNepaliNumber from "get-nepali-number";
import "./table.css";
import TableLoader from "../../components/Loader/TableLoader";

const ReportBody = (props) => {
  const { itemList, isLoading, company, id } = props;
  const {
    companyNameNp,
    districtNameNp,
    stateNameNp,
  } = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div id={id}>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div
              className="col-md-12"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              <h7>{companyNameNp}</h7>
              <h5 className="font-weight-bold">
                {company.companyTypeCode === "MUN" ? "नगरपालिकाको कार्यालय" :
                  (company.companyTypeCode === "RURAL-MUN" ?
                    "गाउँ कार्यपालिकाको कार्यालय" : "")}
              </h5>
              <h7>{companyNameNp}</h7>
              ,&nbsp;
              <h7>{districtNameNp}</h7>
              <br />
              <h7>{stateNameNp}</h7>
              ,&nbsp;
              <h7>नेपाल</h7>
            </div>
            {/*<MDBox>*/}
            {isLoading && isLoading === true ? (
              <TableLoader />
            ) : (
              <table style={{ width: "100%", height: "100%" }}>
                <thead>
                <tr>
                  <th>सि न</th>
                  <th>दर्ता मिति</th>
                  <th>दर्ता नम्बर</th>
                  <th>नाम थर</th>
                  <th>प्रकार</th>
                  <th>च्यानल</th>
                </tr>
                </thead>
                <tbody>
                {itemList &&
                  itemList.map((item) => (
                    <tr key={item.id}>
                      <td align={"center"}>{item.id}</td>
                      <td align={"center"}> {item.submissionDate}</td>
                      <td align={"center"}> {item.registrationNumber ? getNepaliNumber(item.registrationNumber) : "N/A"}</td>
                      <td align={"center"}>{item.name}</td>
                      <td align={"center"}>{item.type}</td>
                      <td align={"center"}>{item.channel}</td>
                    </tr>
                  ))}
                <tr key="total">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>जम्मा</td>
                  <td>{getNepaliNumber(itemList.length)}</td>
                </tr>
                </tbody>
              </table>
            )}
            {/*</MDBox>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportBody;
