import React, { useEffect, useState } from "react";
import governmentLogo from "../../assets/images/govt.svg";
import "./certificate.css";
import { fetchDfoFormSubmissionDetail } from "../../layouts/formSubmission/Services";
import Button from "@mui/material/Button";
import { getTokenFromLocalStorage } from "../../utils/LocalStorageUtils";
import { useParams } from "react-router-dom";
import { generateForestCertificate } from "../../layouts/CommonServices";
var getNepaliNumber = require('get-nepali-number');

const Certificate = () => {
    // console.log("Certificate generation is called", props)
    const routeParams = useParams();
    const {id} = routeParams;

    const [formSubmission, setFormSubmission] = useState([]);
    const [company, setCompany] = useState("");
    const [divName, setDivName] = useState("frontPrint")

    useEffect(async () => {
        const fetchData = async () => {
            const response = await fetchDfoFormSubmissionDetail(id);
            const formSubmission = response.data;
            setFormSubmission(formSubmission)

            const company = await getTokenFromLocalStorage("userInfo");
            setCompany(company);
        };
        await fetchData();
    }, [id]);


    console.log("company:::", company)
    const print = async (divName) => {
        let requestDTO = {
            id: id,
        };
        await generateForestCertificate(requestDTO);
        if (divName === 'bothPrintableArea') {
            await setDivName(divName)
        }
        if (divName === 'frontPrint') {
            await setDivName(divName)
        }
        if (divName === 'backPrint') {
            await setDivName(divName)
        }
        setTimeout(() => {
            window.print(divName)
        }, 2000);
    }

    return (
        <div className="row">

            {divName === 'frontPrint' ?
                <div>
                    <div className="notPrintableArea">
                        <Button className='font-weight-bold' md='12' style={{marginTop: '10px'}}
                                onClick={() => print("frontPrint")}><Button/>&nbsp;प्रिन्ट गर्ने
                        </Button>&nbsp;
                    </div>
                    {formSubmission ? (
                        <div id="printFront" className='fullHeight' style={{marginTop: '-7px'}}>
                            <div className='cardHeight'>
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 card m-2"
                                    style={{
                                        backgroundColor: "#E9E6DF",
                                        color: "black"
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <img
                                                style={{margin: "8px"}}
                                                src={governmentLogo}
                                                alt={"governmentLogo"}
                                                height={190}
                                                width={190}
                                            ></img>
                                        </div>
                                        <div
                                            className="col-lg-6 col-md-6"
                                            style={{textAlign: "center", marginTop: "2px"}}
                                        >
                                            <h7 style={{fontSize: "30px", marginTop: "-8px"}}>
                                                अनुूसूची –४७ (नियम ८१ को उपनियम (३) सँग सम्बन्धित)
                                            </h7>

                                            <div style={{marginTop: '0px'}}>
                                                <span className="font-weight-bold" style={{ fontSize: "32px",
                                                    color: "black",
                                                    textDecoration: "underline", }}>निजी वन दर्ता प्रमाणपत्र{" "}
                                                    {/*{person.isCardTaken && person.isCardTaken === "Y"*/}
                                                    {/*  ? "( प्रतिलिपि )"*/}
                                                    {/*  : null}*/}
                                                </span>
                                            </div>

                                            <h4 className='font-weight-bold'
                                                style={{fontSize: "28px"}}>{company.companyNameNp}</h4>
                                            <h5
                                                className="font-weight-bold"
                                                style={{fontSize: "35px", marginTop: "-8px"}}
                                            >{company.companyTypeCode==='MUN'?"नगरपालिकाको कार्यालय":
                                              (company.companyTypeCode==='RURAL-MUN'?
                                                "गाउँ कार्यपालिकाको कार्यालय":"")}</h5>
                                            <h6 style={{fontSize: "30px", marginTop: "-8px"}}>
                                                {company.districtName}
                                            </h6>

                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        प्रमाणपत्र नं: <b>{formSubmission.registrationNumber?getNepaliNumber(formSubmission.registrationNumber):"N/A"}</b><br></br>
                                        श्री {formSubmission.name}<br></br>
                                        {company.localLevelNameNp}, {company.districtName}।<br></br>
                                        मिति– २०८...÷...÷.....<br></br>
                                        <br></br>
                                        तपाईले {company.districtName} डिभिजन वन कार्यालयको सिफारिस सहित यस
                                        हरिवन नगरपालिकामा निजी वन दर्ता गराई पाउँ
                                        भनी पेस गरेको कागजात अध्ययन हुदाँ वन ऐन, २०७६ र वन नियमावली, २०७९ को अधिनमा
                                        रही देहायको निजी वन तथा निजी आवादी
                                        जमिनमा लगाएको वन पैदावारलाई निजी वनको रुपमा दर्ता गरी यो प्रमाणपत्र दिइएको छ
                                        ।
                                    </div>
                                    <br></br>
                                    <div className="row" style={{marginLeft: "1px", marginTop: "-40px"}}>
                                    <b><ul>निजी वनको विवरण :-</ul></b>
                                        चार किल्ला :-<br></br>
                                        पूर्व :- {formSubmission?.east?getNepaliNumber(formSubmission.east):""} <br></br>
                                        पश्चिम :- {formSubmission?.west?getNepaliNumber(formSubmission.west):""} <br></br>
                                        उत्तर :- {formSubmission?.north?getNepaliNumber(formSubmission.north):""} <br></br>
                                        दक्षिण :- {formSubmission?.south?getNepaliNumber(formSubmission.south):""} <br></br>
                                        कित्ता नं. र क्षेत्रफल :- {formSubmission?.area?getNepaliNumber(formSubmission.area):""} <br></br>
                                        <b><ul>वन पैदावारको प्रजाती र संख्या :-</ul></b> <br></br>
                                        प्रमाणपत्र दिने अधिकृतको :- <br></br>
                                        नाम,थर :- <br></br>
                                        दर्जा :- प्रमुख प्रशासकिय अधिकृत <br></br>
                                        दस्तखत :- <br></br>
                                    </div>
                                    <div className="font-weight-bold col-md-6 personal_details " style={{marginTop: '-2px'}}>
                    <span style={{
                        textDecoration: "underline",
                    }}>परिचय पत्र प्रमाणित गर्ने:</span>
                                        <div
                                          className="personal_details"
                                        >
                                            <span>नाम थर :</span>&nbsp;&nbsp;&nbsp;
                                            <span>{company?.nameEng}</span>
                                            <br/>
                                            <span>दर्जा :</span>&nbsp;&nbsp;&nbsp;
                                            <span>प्रमुख प्रशासकिय अधिकृत</span>
                                            <br/>
                                            <span><b>हस्ताक्षर :</b></span>
                                            <br/>
                                            <span>मिति :</span>&nbsp;&nbsp;&nbsp;
                                            {/*<span>{person.approvedDate?englishToNepaliNumber(adToBs(person.approvedDate)):''}</span>*/}
                                        </div>
                                    </div>
                                    <div style={{textAlign: "center", }}>
                                    <span
                                      className="font-weight-bold"
                                      style={{fontSize: "22px"}}
                                    >द्रष्टव्य :– यो प्रमाणपत्र हराएमा, च्यातिएमा, वा नासिएमा अर्को प्रमाणपत्र लिन पच्चीस रुपैयाँ दस्तुर लाग्नेछ ।</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        "<Page404/>"
                    )}
                </div> : ''}
        </div>
    );
};

export default Certificate;
